const SET_ADDR_INFO = "SET_ADDR_INFO";
const SET_REQ_TASK_INFO = "SET_REQ_TASK_INFO";
const SET_GRP_BR_NO = "SET_GRP_BR_NO";
const SET_BR_NO = "SET_BR_NO";
const SET_CLEAR_REQ_TASK_INFO = "SET_CLEAR_REQ_TASK_INFO";
const SET_CLEAR_REQ_TASK_INFO2 = "SET_CLEAR_REQ_TASK_INFO2";
const SET_ECLEAN_YN = "SET_ECLEAN_YN";


const initialState = {
  isFirst: true,
  reqTaskInfo: {
    reqNo: "",
    stCd: "",
    brNo: "",
    name: "",
    hphnNo: "",
    resRegNoFront: "",
    resRegNoBack: "",
    bdt: "",
    emalAddr: "",
    emalDmn: "",
    postNo: "",
    addr: "",
    dtlAddr: "",
    sex: "",
    bankCd: "",
    actNo: "",
    actNm: "",
    natlNm: "",
    crerYn: "",
    bgBrNo:"",
    buBrNo: "",
    recrNm: "",
    recrEmpNo: "",
    recrOflv: "",
    recrTel: "",
    financialStat: "",
    finAcdntCorpNm: "",
    finAcdntKind: "",
    acdntDtm: "",
    acdntCtnt: "",
    acdntDtlCtnt: "",
    financial2Stat: "",
    dissCorpNm: "",
    dissFinAcdntKind: "",
    dissDtm: "",
    dissDtlCtnt: "",
    eCleanYn: "0" ,
    faxNo: "",
  },
};

const insert = (state = initialState, { type, ...rest }) => {
  let _reqTaskInfo = Object.assign({}, state.reqTaskInfo);

  switch (type) {
    case SET_ADDR_INFO:
      _reqTaskInfo = {
        ...state.reqTaskInfo,
        addr: rest.payload.addr,
        postNo: rest.payload.postNo,
      };

      return {
        ...state,
        reqTaskInfo: _reqTaskInfo,
      };
    case SET_REQ_TASK_INFO:
      _reqTaskInfo = { ...state.reqTaskInfo, ...rest.payload.changeData };
      return {
        ...state,
        isFirst: rest.payload.isFirst ? false : state.isFirst,
        reqTaskInfo: _reqTaskInfo,
      };
    case SET_GRP_BR_NO:
      const { buBrNo } = rest.payload;
      let newReqTaskInfo = { ...state.reqTaskInfo, buBrNo };

      return {
        ...state,
        reqTaskInfo: newReqTaskInfo,
      };
    case SET_BR_NO:
      const { brNo } = rest.payload;
      let newReqTaskInfo2 = { ...state.reqTaskInfo, brNo };

      return {
        ...state,
        reqTaskInfo: newReqTaskInfo2,
      };
    case SET_ECLEAN_YN:
      let { eCleanYn } = rest.payload;
      let newReqTaskInfo3 = { ...state.reqTaskInfo, eCleanYn };

      return {
        ...state,
        reqTaskInfo: newReqTaskInfo3,
      };
      
    case SET_CLEAR_REQ_TASK_INFO:
      _reqTaskInfo = {
        ...state.reqTaskInfo,
        finAcdntCorpNm: '',
        finAcdntKind: '',
        acdntDtm: '',
        acdntCtnt: '',
        acdntDtlCtnt: '',
      };

      return {
        ...state,
        reqTaskInfo: _reqTaskInfo,
      };
    case SET_CLEAR_REQ_TASK_INFO2:
      _reqTaskInfo = {
        ...state.reqTaskInfo,
        dissCorpNm: '',
        dissFinAcdntKind: '',
        dissDtm: '',
        dissDtlCtnt: '',
      };

      return {
        ...state,
        reqTaskInfo: _reqTaskInfo,
      };
    case "persist/PURGE":
      return initialState;
    default:
      return state;
  }
};

export const setAddrInfo = (addrInfo) => ({
  type: SET_ADDR_INFO,
  payload: { addr: addrInfo.addr, postNo: addrInfo.postNo },
});
export const setReqTaskInfo = (changeData, isFirst) => ({
  type: SET_REQ_TASK_INFO,
  payload: { changeData, isFirst },
});
export const setGrpBrNo = (buBrNo) => ({
  type: SET_GRP_BR_NO,
  payload: { buBrNo },
});
export const setBrNo = (brNo) => ({
  type: SET_BR_NO,
  payload: { brNo },
});
export const setClearReqTaskInfo = () => ({
    type: SET_CLEAR_REQ_TASK_INFO,
});
export const setClearReqTaskInfo2 = () => ({
    type: SET_CLEAR_REQ_TASK_INFO2,
});
export const setECleanYn = (eCleanYn) => ({
  type: SET_ECLEAN_YN,
  payload: { eCleanYn },
  
}) 

export default insert;
