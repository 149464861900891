import React, { Component } from 'react';
// import { HashRouter, Route, Switch } from 'react-router-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import './scss/style.scss';
// import './scss/ezp2/ezp2Comm.css';
// import './scss/ezp2/ezp2LayoutComm.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>  
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const CustRequestLayout = React.lazy(() => import('./containers/custRequest/CustRequestLayout'));
const MemLayout = React.lazy(() => import('./containers/ezp2/mem/Layout'));
const CustLayout = React.lazy(() => import('./containers/ezp2/cust/Layout'));

// Email App
// const TheEmailApp = React.lazy(() => import('./views/backup/apps/email/TheEmailApp'));

// Pages
const Login = React.lazy(() => import('./views/admin/login/Login'));
const TemPwd = React.lazy(() => import('./views/admin/login/TemPwd'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/error/Page404'));
const Page500 = React.lazy(() => import('./views/error/Page500'));

// MemSso
const MemSso = React.lazy(() => import('./views/ezp2/mem/MemSso'));

const RequestVerifyAuth = React.lazy(() => import('./views/kakaocert/RequestVerifyAuth'));
class App extends Component {

  render() {
    let path;

    // "/admin" 으로 시작하는경우 관리자 페이지로 이동    
    if(window.location.pathname.indexOf("/admin") === 0) {
      path = "/admin";
      return (
        <BrowserRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route exact path={`${path}/login`} name="Login Page" render={props => <Login {...props}/>} />
                <Route exact path={`${path}/tempwd`} name="Login Page" render={props => <TemPwd {...props}/>} /> 
                {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
                <Route exact path={`${path}/404`} name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path={`${path}/500`} name="Page 500" render={props => <Page500 {...props}/>} />
                {/* <Route path="/apps/email" name="Email App" render={props => <TheEmailApp {...props}/>} /> */}
                <Route path={`${path}/`} name="Main" render={props => <TheLayout {...props}/>} />
              </Switch>
            </React.Suspense>
        </BrowserRouter>
      );
    } else if (window.location.pathname === '/ezp-2/sso'){
      return (
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path={'*'} name="EasyPaper sso" render={props => <MemSso {...props}/>} />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      );
    } else if (window.location.pathname.indexOf("/kakaocert") === 0){
      path = "/kakaocert";
    } else if(window.location.pathname.indexOf("/ezp-2") === 0) {
      path = "/ezp-2";
      return (
        <BrowserRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route exact path={`${path}/404`} name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path={`${path}/500`} name="Page 500" render={props => <Page500 {...props}/>} />
                <Route path={`${path}/`} name="Ezp2 Mem Main" render={props => <MemLayout {...props}/>} />
                <Route path={`${path}/ct`} name="Ezp2 Cust Main" render={props => <CustLayout {...props}/>} />
              </Switch>
            </React.Suspense>
        </BrowserRouter>
      );

    }else {
      return (
        <BrowserRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                <Route path="/" name="EZP1 Home" render={props => <CustRequestLayout {...props}/>} />
                <Route exact path={`${path}/RequestVerifyAuth`} name="kakaocert Page" render={props => <RequestVerifyAuth {...props}/>} />
              </Switch>
            </React.Suspense>
        </BrowserRouter>
      );
    }
  }
}

export default App;
