const INIT_CMPL_DOC_FILES = "INIT_CMPL_DOC_FILES";
const SET_CMPL_DOC_FILE = "SET_CMPL_DOC_FILE";
const CHANGE_CMPL_DOC_FILE ="CHANGE_CMPL_DOC_FILE";
const SET_MODIFY_CMPL_DOC_FILE = "SET_MODIFY_CMPL_DOC_FILE";

const initialState = {
  isFirst: true,
  cmplDocFiles: []
};

const custDoc = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case INIT_CMPL_DOC_FILES:
      let _initCmplDocFiles = [];
      return {
        ...state,
        isFirst: true,
        cmplDocFiles: _initCmplDocFiles,
      };
    case SET_CMPL_DOC_FILE:
      return {
        ...state,
        cmplDocFiles: rest.payload.initData,
      };
    case CHANGE_CMPL_DOC_FILE:
      const { docCatCd } = rest.payload

      let newCmplDocFiles = state.cmplDocFiles.map((item) => {
        if(String(item.docCatCd) === String(docCatCd)) {
          return {
            ...item, 
            file: [],
            fileInfo: [],
          };
        } else {
          return item;
        }
      });
    return {
      ...state,
      cmplDocFiles: newCmplDocFiles,
    };
    case SET_MODIFY_CMPL_DOC_FILE:
      const _cmplDocFiles = [];

      state.cmplDocFiles.forEach((cmplDocFile) => {
        if(rest.payload.cmplDocData.length){
          let existDocFile = rest.payload.cmplDocData.filter(
            (item) => Number(item.FORM_CAT_CD) === Number(cmplDocFile.docCatCd)
            );
            if (existDocFile.length) {
              _cmplDocFiles.push({
                ...cmplDocFile,
                file: cmplDocFile.file = existDocFile[0].FILE,
                fileInfo: cmplDocFile.fileInfo = existDocFile[0].FILE_INFO,
                update: cmplDocFile.update = existDocFile[0].update,
                realFileNm : cmplDocFile.update = existDocFile[0].realFileNm,
              });
            } else {
              _cmplDocFiles.push(cmplDocFile);
            }
          } else {
            _cmplDocFiles.push(cmplDocFile);
          }

        });
      return {
        ...state,
        isFirst: rest.payload.isFirst ? false : state.isFirst,
        cmplDocFiles: _cmplDocFiles,
     };    

     
    case "persist/PURGE":
      return initialState;
    default:
      return state;
  }
};

export const initCmplDocFile = () => ({
  type: INIT_CMPL_DOC_FILES,
});
export const setCmplDocFile = (initData) => ({
  type: SET_CMPL_DOC_FILE,
  payload: { initData },
});

export const changeCmplDocFile = (docCatCd) => ({
  type: CHANGE_CMPL_DOC_FILE,
  payload: { docCatCd },
});
export const setModifyCmplDocFile = (cmplDocData, isFirst) => ({
  type: SET_MODIFY_CMPL_DOC_FILE,
  payload: { cmplDocData, isFirst },
});

export default custDoc;
