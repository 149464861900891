// reducers/index.js
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import admin from "./admin";
import main from "./main";
import insert from "./insert";
// import form from "./form";
import apoCntrForm from "./apoCntrForm";
import apoDocForm from "./apoDocForm";
import sbmsDoc from "./sbmsDoc";
import cust from "./ezp2/cust/cust";
import memInfo from "./ezp2/mem/memInfo";
import memRegImgInfo from "./ezp2/mem/memRegImgInfo";
/* EZP2 */
import tasks from './ezp2/mem/tasks';
import custInfo from './ezp2/cust/custInfo';
import prod from './ezp2/mem/prod';
import custDoc from './ezp2/cust/custDoc'

const persistConfig = {
  key: "ezp",
  // localStorage에 저장.
  storage: storage,
  // reducer 중에 whitelist에 포함되어 있는 reducer만 localstorage에 저장.
  whitelist: ["main", "insert", "apoCntrForm", "apoDocForm", "sbmsDoc", "cust", "memInfo","memRegImgInfo", "tasks", "custInfo", "prod", "custDoc"]
  // blacklist 사용 시 제외
};

const rootReducer = combineReducers({
  main, insert, apoCntrForm, apoDocForm, sbmsDoc, admin, cust, memInfo, memRegImgInfo, tasks, custInfo, prod, custDoc
});

export default persistReducer(persistConfig, rootReducer);