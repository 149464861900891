const INIT = 'prod/INIT';
const SET_CNTR_INFO = 'prod/SET_CNTR_INFO';
const SET_CHANGE_PROD_NM = 'prod/SET_CHANGE_PROD_NM';
const SET_CORP_ADD_INFO = 'prod/SET_CORP_ADD_INFO';
const SET_PROD_ADD_INFO = 'prod/SET_PROD_ADD_INFO';
const SET_PROD_INFO = 'prod/SET_PROD_INFO';
const SET_CMPR_PROD_LIST = 'prod/SET_CMPR_PROD_LIST';
const SET_CHG_CMPR_PROD_INFO = 'prod/SET_CHG_CMPR_PROD_INFO';
const SET_PROD_SUB_INFO = 'prod/SET_PROD_SUB_INFO';
const SET_PROD_TEMP_SAVE_INFO = 'prod/SET_PROD_TEMP_SAVE_INFO';
const SET_CAR_YN = 'prod/SET_CAR_YN';
const SET_ASS_API_PROD_INFO = 'prod/SET_ASS_API_PROD_INFO';
const SET_ASS_API_CHG_CMPR_PROD_INFO = 'prod/SET_ASS_API_CHG_CMPR_PROD_INFO';

const initialState = {
  hasNotProd: false,
  polNo: '',
  isrd: '',
  isrdBdt: '',
  cntrDt: '',
  mmPrem: '',
  fullPremYn: '0',
  whlLifeYn: '0',
  carYn: '0',
  nmlYn: '0',
  prodInfo: {
    productId: '',
    productNm: '',
    sectionId: '',
    divisionId: '',
    gubunId: '',
    companyNm: '',
    companyCode: '',
    companyGuideNm: '',
    companyImageNm: '',
    afcrNo: '',
  },
  cmprProdInfo: {
    companyCode1: '',
    cmprProdAfcr1: '',
    cmprProdNm1: '',
    companyCode2: '',
    cmprProdAfcr2: '',
    cmprProdNm2: '',
  },
  corpAddInfo: '',
  prodAddInfo: '',
  assApiProdInfo: {},
};

export default function prod(state = initialState, { type, payload }) {
  let copyProdInfo = Object.assign({}, state.prodInfo);
  let copyCmprProdInfo = Object.assign({}, state.cmprProdInfo);
  let copyCorpAddInfo = Object.assign({}, state.corpAddInfo);
  let copyAssApiProdInfo = Object.assign({}, state.assApiProdInfo);
  
  switch (type) {
    case INIT:
      return initialState;
    case SET_CNTR_INFO:
      copyProdInfo = {
        ...copyProdInfo,
        productNm: payload.prodInfo.productNm,
        sectionId: payload.prodInfo.sectionId,
        gubunId: payload.prodInfo.gubunId,
        companyNm: payload.prodInfo.companyNm,
        companyCode: payload.prodInfo.companyCode,
        afcrNo: payload.prodInfo.afcrNo,
      };

      return { 
        ...state,
        polNo: payload.polNo,
        isrd: payload.isrd,
        isrdBdt: payload.isrdBdt,
        cntrDt: payload.cntrDt,
        mmPrem: payload.mmPrem,
        fullPremYn: payload.fullPremYn,
        whlLifeYn: payload.whlLifeYn ? payload.whlLifeYn : whlLifeYn,
        carYn: payload.carYn ? payload.carYn : carYn,
        nmlYn: payload.nmlYn ? payload.nmlYn : nmlYn,
        prodInfo: copyProdInfo,
      };
    case SET_CHANGE_PROD_NM:
      copyProdInfo = {
        ...copyProdInfo,
        productNm: payload,
      };
      return { 
        ...state,
        prodInfo: copyProdInfo,
      };
    case SET_CORP_ADD_INFO:
      return { 
        ...state,
        corpAddInfo: payload,
      };
    case SET_PROD_ADD_INFO:
      return { 
        ...state,
        prodAddInfo: payload,
      };
    case SET_PROD_INFO:
      const { 
        divisionId,
        sectionId,
      } = payload;
      let whlLifeYnVal = '0';
      let carYnVal = '0';
      let nmlYnVal = '0';

      // 종신
      if(String(sectionId) === '01' && String(divisionId) === '01') whlLifeYnVal = '1';
      if(String(sectionId) === '01' && String(divisionId) === '05') whlLifeYnVal = '1';
      if(String(sectionId) === '03' && String(divisionId) === '01') whlLifeYnVal = '1';
      if(String(sectionId) === '03' && String(divisionId) === '02') whlLifeYnVal = '1';
      // 자동차, 일반
      if(String(sectionId) === '10') carYnVal = '1';
      if(String(sectionId) === '11') nmlYnVal = '1';

      copyProdInfo = payload;
      copyProdInfo.afcrNo = '';

      return { 
        ...state,
        whlLifeYn: whlLifeYnVal,
        carYn: carYnVal,
        nmlYn: nmlYnVal,
        prodInfo: copyProdInfo,
      };
    case SET_CMPR_PROD_LIST:
      payload.forEach((item, index) => {
        let companyCode = 'companyCode' + (index + 1);
        let cmprProdNm = 'cmprProdNm' + (index + 1);
        let cmprProdAfcr = 'cmprProdAfcr' + (index + 1);

        copyCmprProdInfo[companyCode] = item.companyCode;
        copyCmprProdInfo[cmprProdNm] = item.productNm;
        copyCmprProdInfo[cmprProdAfcr] = item.companyNm;
      });

      return { 
        ...state,
        cmprProdInfo: copyCmprProdInfo,
      };
    case SET_CHG_CMPR_PROD_INFO:
      let { ord, companyCode, cmprProdAfcr, cmprProdNm } = payload;
      
      if(ord === '1ST') {
        copyCmprProdInfo.companyCode1 = companyCode;
        copyCmprProdInfo.cmprProdAfcr1 = cmprProdAfcr;
        copyCmprProdInfo.cmprProdNm1 = cmprProdNm;
      } else {
        copyCmprProdInfo.companyCode2 = companyCode;
        copyCmprProdInfo.cmprProdAfcr2 = cmprProdAfcr;
        copyCmprProdInfo.cmprProdNm2 = cmprProdNm;
      }

      return { 
        ...state,
        cmprProdInfo: copyCmprProdInfo,
      };
    case SET_PROD_SUB_INFO:
      let { name, value } = payload;

      if(name === 'polNo') {
        value = value.replace(/[^a-zA-Z0-9_-]/g,''); 
      }
      if(name === "mmPrem"){
        value = value.replace(/[^0-9]/g,'');
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if(name === 'fullPremYn') value = value === '0' ? '1' : '0';

      return { 
        ...state,
        [name]: value,
       };
    case SET_CAR_YN:
      let { setCarYn } = payload;
      return { 
        ...state,
        carYn: setCarYn,
       };
    case SET_PROD_TEMP_SAVE_INFO:
      let {
        hasNotProd,
        polNo,
        isrd,
        isrdBdt,
        cntrDt,
        mmPrem,
        fullPremYn,
        whlLifeYn,
        carYn,
        nmlYn,
      } = payload.prod;
      
      return { 
        ...state,
        hasNotProd,
        polNo,
        isrd,
        isrdBdt,
        cntrDt,
        mmPrem,
        fullPremYn,
        whlLifeYn,
        carYn,
        nmlYn,
        prodInfo: {
          ...copyProdInfo,
          ...payload.prodInfo,
        },
        cmprProdInfo: {
          ...copyCmprProdInfo,
          ...payload.cmprProdInfo,
        },
      };
      case SET_ASS_API_PROD_INFO:
        return { 
          ...state,
          assApiProdInfo: payload
        };
      case SET_ASS_API_CHG_CMPR_PROD_INFO:
        return { 
          ...state,
          assApiProdInfo: payload,
        };
    case 'persist/PURGE':
      return initialState;
    default:
      return state;
  }
}

export function init() {
  return {
    type: INIT,
  };
}

export function setCntrInfo(data) {
  return {
    type: SET_CNTR_INFO,
    payload: data,
  };
}

export function setChangeProdNm(data) {
  return {
    type: SET_CHANGE_PROD_NM,
    payload: data,
  };
}

export function setCorpAddInfo(data) {
  return {
    type: SET_CORP_ADD_INFO,
    payload: data,
  };
}

export function setProdAddInfo(data) {
  return {
    type: SET_PROD_ADD_INFO,
    payload: data,
  };
}

export function setProdInfo(data) {
  return {
    type: SET_PROD_INFO,
    payload: data,
  };
}

export function setCmprProdList(data) {
  return {
    type: SET_CMPR_PROD_LIST,
    payload: data,
  };
}

export function setChgCmprProdInfo(data) {
  return {
    type: SET_CHG_CMPR_PROD_INFO,
    payload: data,
  };
}

export function setProdSubInfo(name, value) {
  return {
    type: SET_PROD_SUB_INFO,
    payload: { name, value },
  };
}

export function setCarYn(setCarYn) {
  return {
    type: SET_CAR_YN,
    payload: { setCarYn },
  };
}

export function setProdTempSaveInfo(prod, prodInfo, cmprProdInfo) {
  return {
    type: SET_PROD_TEMP_SAVE_INFO,
    payload: { 
      prod,
      prodInfo,
      cmprProdInfo,
    },
  };
}

// 협회API 상품 세팅
export function setAssApiProdInfo(data) {
  return {
    type: SET_ASS_API_PROD_INFO,
    payload: data,
  };
}

// 협회 API 비교상품 변경
export function setAssApiChgCmprProdInfo(data) {
  return {
    type: SET_ASS_API_CHG_CMPR_PROD_INFO,
    payload: data,
  };
}