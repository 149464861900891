
class Loader {
  static setLoader(dispatch) {
    Loader.dispatch = dispatch;
  }
  
  static setSilent(slient) {
    Loader.slient = slient;
  }

  static showLoader(show) {
    if(!Loader.slient){
      Loader.dispatch({type: 'set', loaderShow: show});
    }
  }
}

Loader.dispatch = null;
Loader.slient = false;

export default Loader;
