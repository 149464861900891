const SET_APO_CNTR_HDWT_INFO = "SET_APO_CNTR_HDWT_INFO";
const SET_APO_CNTR_HDWT_DATA = "SET_APO_CNTR_HDWT_DATA";
const CHG_APO_CNTR_HDWT_DATA = "CHG_APO_CNTR_HDWT_DATA";
const SET_MODIFY_APO_CNTR_HDWT_DATA = "SET_MODIFY_APO_CNTR_HDWT_DATA";

const initialState = {
  isFirst: true,
  apoCntrHdwtInfo: [],
};

const apoCntrForm = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case SET_APO_CNTR_HDWT_INFO:
      const _apoCntrHdwtInfo = [];
      let { info } = rest.payload;

      info.map((item) => {
        _apoCntrHdwtInfo.push({
          hdwtCatCd: item.HDWT_CAT_CD,
          text: item.HDWT_TEXT,
          signYn: item.SIGN_YN,
          data: '',
          update: false,
        })
      })

      return {
        ...state,
        isFirst: isFirst ? false : state.isFirst,
        apoCntrHdwtInfo: _apoCntrHdwtInfo,
      };
    case SET_APO_CNTR_HDWT_DATA:
      const { hdwtData, isFirst } = rest.payload;

      let _apoCntrHdwtData = state.apoCntrHdwtInfo.map((item) => {
        let temp = hdwtData.filter((item2) => item.hdwtCatCd === item2.HDWT_CAT_CD);

        if(temp.length) {
            return {
              ...item,
              data:temp[0].IMG_DATA,
              update: isFirst ? false : true,
            };
          } else {
            return item;
          }
      })

      return {
        ...state,
        isFirst: isFirst ? false : state.isFirst,
        apoCntrHdwtInfo: _apoCntrHdwtData,
      };
    case CHG_APO_CNTR_HDWT_DATA:
      let { chgData } = rest.payload;

      const chgApoCntrHdwtInfo = state.apoCntrHdwtInfo.map((item) => {
        return item.hdwtCatCd === chgData.hdwtCatCd
          ? { ...item, data: chgData.data, update: true }
          : item;
      });
      return {
        ...state,
        apoCntrHdwtInfo: chgApoCntrHdwtInfo,
      };
    case SET_MODIFY_APO_CNTR_HDWT_DATA:
      const __apoCntrHdwtInfo = state.apoCntrHdwtInfo.map((item) => {
        return item.hdwtCatCd === rest.payload.hdwtCatCd
          ? { ...item, data: undefined, update: false }
          : item;
      });
      return {
        ...state,
        apoCntrHdwtInfo: __apoCntrHdwtInfo,
      };
    case "persist/PURGE":
      return initialState;
    default:
      return state;
  }
};

export const setApoCntrHdwtInfo = (info) => ({
  type: SET_APO_CNTR_HDWT_INFO,
  payload: { info },
});
export const setApoCntrHdwtData = (hdwtData, isFirst) => ({
  type: SET_APO_CNTR_HDWT_DATA,
  payload: { hdwtData, isFirst },
});
export const chgApoCntrHdwtData = (chgData) => ({
  type: CHG_APO_CNTR_HDWT_DATA,
  payload: { chgData },
});
export const setModifyApoCntrHdwtData = (hdwtCatCd) => ({
  type: SET_MODIFY_APO_CNTR_HDWT_DATA,
  payload: { hdwtCatCd },
});

export default apoCntrForm;
