const SET_CUST_REL_INFO = 'cust/SET_CUST_REL_INFO';
const SET_SIGN_IMG_DATA = 'cust/SET_SIGN_IMG_DATA';
const SET_MINR_SIGN_IMG_DATA = 'cust/SET_MINR_SIGN_IMG_DATA';
const EMPTY_SIGN_IMG_DATA = 'cust/EMPTY_SIGN_IMG_DATA';
const EMPTY_MINR_SIGN_IMG_DATA = 'cust/EMPTY_MINR_SIGN_IMG_DATA';
const SET_PAGE_ARRY = 'cust/SET_PAGE_ARRY';
const SET_STATE = 'cust/SET_STATE';

const initialState = {
  pageArry: [],
  step:'',
  agrDate:'',
  stCd : '',
  infoSplyPrdCd:'',
  minrYn:'',
  carYn:'',
  mktAgrYn:'',
  corpReqItem:'',
  custInfo: {
    custKey: '',
    custReqNo:'',
    corpNo:'',
    ifKey:'',
    mmPrem:'',
    memNo: '',
    memNm:'',
    isrd:'',
    custNo: '',
    custNm: '',
    hphnNo:'',
    addr:'',
    bdt: '',
    sex: '',
    polNo: '',
    prodNo: '',
    prodAfcr: '',
    prodNm: '',
    whlLifeYn: '',
    cmprAfcrNm1: '',
    cmprProdNm1: '',
    cmprAfcrNm2: '',
    cmprProdNm2: '',
    prntAtrtNm:'',
    prntCatCd : '',
    signData: '',
    minrSignData: '',
    nowDate:'',
  },
};

export default function cust(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CUST_REL_INFO:
      return payload;
    case SET_PAGE_ARRY:
    return { ...state, pageArry: payload };
    case SET_SIGN_IMG_DATA:
      let custInfoAddSign = Object.assign({}, state.custInfo);
      custInfoAddSign = {...custInfoAddSign, signData: payload};
      return { ...state, custInfo: custInfoAddSign};
    case SET_MINR_SIGN_IMG_DATA:
      let custInfoAddMinrSign = Object.assign({}, state.custInfo);
      custInfoAddMinrSign = {...custInfoAddMinrSign, minrSignData: payload};
      return { ...state, custInfo: custInfoAddMinrSign};
    case EMPTY_SIGN_IMG_DATA:
      let custInfoDelSign = Object.assign({}, state.custInfo);
      custInfoDelSign = {...custInfoDelSign, signData: payload};
      return { ...state, custInfo: custInfoDelSign};
    case EMPTY_MINR_SIGN_IMG_DATA:
      let custMinrInfoDelSign = Object.assign({}, state.custInfo);
      custMinrInfoDelSign = {...custMinrInfoDelSign, minrSignData: payload};
      return { ...state, custInfo: custMinrInfoDelSign};
    case SET_STATE:
      const changeData = payload.changeData;
      const name = payload.name;
      return {
        ...state,
        [name]: changeData,
      }  
    case 'persist/PURGE':
      return initialState;

    default:
      return state;
  }
}


export function setCustRelInfo(data) {
  return {
    type: SET_CUST_REL_INFO,
    payload: data,
  };
}
export function setCustCanvasImgData(data) {
  return {
    type: SET_SIGN_IMG_DATA,
    payload: data,
  };
}
export function setCustMinrCanvasImgData(data) {
  return {
    type: SET_MINR_SIGN_IMG_DATA,
    payload: data,
  };
}
export function emptyCustImgData() {
  return {
    type: EMPTY_SIGN_IMG_DATA,
    payload: undefined,
  };
}
export function emptyCustMinrImgData() {
  return {
    type: EMPTY_MINR_SIGN_IMG_DATA,
    payload: undefined,
  };
}

export function setAgreeArry(data) {
  return {
    type: SET_PAGE_ARRY,
    payload: data,
  };
}
export function setState(name, changeData) {
  return {
    type: SET_STATE,
    payload : {name, changeData}
  }
}
