const INIT = 'tasks/INIT';
const SET_PREV_TASKS = 'tasks/SET_PREV_TASKS';
const SET_INIT_TASKS = 'tasks/SET_INIT_TASKS';
const SET_SEL_TASK_NO = 'tasks/SET_SEL_TASK_NO';
const SET_SEL_TASK_ALL = 'tasks/SET_SEL_TASK_ALL';

const initialState = {
  isFirst: true,
  taskNoList: [],
};

export default function tasks(state = initialState, { type, payload }) {
  let copyTaskNoList = [...state.taskNoList];
  
  switch (type) {
    case INIT:
      return initialState;
    case SET_INIT_TASKS:
      // let taskInfo = payload
      // .filter((item) => item.TASK_CAT_CD === '06')
      // .map((item) => ({taskNo: String(item.TASK_NO), taskCatCd: item.TASK_CAT_CD, isFixed: '1'}));

      return { 
        ...state,
        isFirst: false,
        // taskNoList: taskInfo,
      };
      case SET_PREV_TASKS:
        return { 
        ...state,
        isFirst: false,
        taskNoList: payload,
      };
    case SET_SEL_TASK_NO:
      const { taskNo, taskCatCd, isFixed } = payload;

      if (payload.checked && isFixed === '0') {
        copyTaskNoList = copyTaskNoList.concat([{taskNo, taskCatCd, isFixed}]);
      }
      if (!payload.checked && isFixed === '0') {
        copyTaskNoList = copyTaskNoList.filter((item) => item.taskNo !== taskNo);
      }
      
      return { 
        ...state,
        taskNoList: copyTaskNoList,
      };
    case SET_SEL_TASK_ALL:
      let { tasks } = payload;

      if (payload.checked) {
        copyTaskNoList = tasks;
      } else {
        copyTaskNoList = copyTaskNoList.filter((item) => item.isFixed === '1');
      }
      
      return { 
        ...state,
        taskNoList: copyTaskNoList,
      };
    case 'persist/PURGE':
      return initialState;
    default:
      return state;
  }
}

export function init() {
  return {
    type: INIT,
  };
}

export function setInitTasks(data) {
  return {
    type: SET_INIT_TASKS,
    payload: data,
  };
}

export function setPrevTasks(data) {
  return {
    type: SET_PREV_TASKS,
    payload: data,
  };
}

export function setSelTaskNo(taskNo, taskCatCd, isFixed, checked) {
  return {
    type: SET_SEL_TASK_NO,
    payload: { taskNo, taskCatCd, isFixed, checked },
  };
}

export function setSelTaskAll(tasks, checked) {
  return {
    type: SET_SEL_TASK_ALL,
    payload: { tasks, checked },
  };
}