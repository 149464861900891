const SET_MEM_INIT_DATA = "mem/SET_MEM_INIT_DATA";
const SET_MEM_INFO = "SET_MEM_INFO";
const SET_SIGN_IMG_DATA = 'mem/SET_SIGN_IMG_DATA';
const EMPTY_SIGN_IMG_DATA = 'mem/EMPTY_SIGN_IMG_DATA';
const MEM_INFO_CHANGE = 'mem/MEM_INFO_CHANGE';

const initialState = {
  step: 0,
  memNo: "",
  easyLoginYn: "",
  prflFileNm:"",
  logoFileNm:"",
  memNm:"",
  memHphnNo:"",
  emal:"",
  corpNo: "",
  ifKey: "",
  signData:"",
  corpCrtfStCd:"",
  erpNo:"",
  brNm:"",
  regDocCnt: 0,
  erpUseYn: "",
  assUseYn: "",
  exclYn: "",
};

const memInfo = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case SET_MEM_INIT_DATA:
      const { 
        corpNo,
        ifKey,
        assUseYn,
        exclYn,
        corpCrtfStCd,
        signData,
        regDocCnt,
      } = rest.payload;

      return {
        ...state,
        corpNo,
        ifKey,
        assUseYn,
        exclYn,
        corpCrtfStCd,
        signData,
        regDocCnt,
      }
    case SET_MEM_INFO:
      return {
        ...state,
        step: 0,
        memNo: rest.payload.memNo,
        easyLoginYn: rest.payload.easyLoginYn,
        prflFileNm: rest.payload.prflFileNm,
        logoFileNm: rest.payload.logoFileNm,
        memNm: rest.payload.memNm,
        memHphnNo: rest.payload.memHphnNo,
        emal: rest.payload.emal,
        corpNo: rest.payload.corpNo,
        ifKey: rest.payload.ifKey,
        signData:rest.payload.signData,
        corpCrtfStCd:rest.payload.corpCrtfStCd,
        erpNo:rest.payload.erpNo,
        brNm:rest.payload.brNm,
        erpUseYn: rest.payload.erpUseYn,
        assUseYn: rest.payload.assUseYn,
        exclYn: rest.payload.exclYn,
      };
    case SET_SIGN_IMG_DATA:
      return { ...state, signData: rest.payload };
    case EMPTY_SIGN_IMG_DATA:
      return { ...state, signData: rest.payload };  
    case MEM_INFO_CHANGE:
      const newInfo = rest.payload.changeData;
      const name = rest.payload.name;
      var value;
      if(name != "productInfo"){
        value = newInfo;
      }else{
        value = [newInfo];
      }
      return {
        ...state,
        [name]: value,
      }      
    case "persist/PURGE":
      return initialState;
    default:
      return state;
  }
};

export function setMemInitData(data) {
  return {
    type: SET_MEM_INIT_DATA,
    payload: data,
  };
}
export const setMemInfo = ( memInfo ) => ({
  type: SET_MEM_INFO,
  payload:  memInfo ,
});
export function setMemCanvasImgData(data) {
  return {
    type: SET_SIGN_IMG_DATA,
    payload: data,
  };
}
export function emptyMemImgData() {
  return {
    type: EMPTY_SIGN_IMG_DATA,
    payload: undefined,
  };
}
export function setMemInfoChange(changeData, name) {
  return {
    type: MEM_INFO_CHANGE, 
  payload: { changeData, name },
  }
}


export default memInfo;
