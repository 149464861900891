const INIT_SBMS_DOC_FILES = "INIT_SBMS_DOC_FILES";
const SET_SBMS_DOC_FILE = "SET_SBMS_DOC_FILE";
const SET_MODIFY_SBMS_DOC_FILE = "SET_MODIFY_SBMS_DOC_FILE";
const SET_SBMS_DOC_FILE_INFO = "SET_SBMS_DOC_FILE_INFO";
const DELETE_PREV_SBMS_DOC_FILE = "DELETE_PREV_SBMS_DOC_FILE";
const DELETE_SBMS_DOC_FILE = "DELETE_SBMS_DOC_FILE";
const CHANGE_SBMS_DOC_FILE = "CHANGE_SBMS_DOC_FILE";
const SET_UPDATE_FAILE = "SET_UPDATE_FAILE";
const DELETE_HAS_BLANK_FILE = "DELETE_HAS_BLANK_FILE";
const SET_IS_FIRST_TRUE = "SET_IS_FIRST_TRUE";
const CLEAR_DELETE_FILES = "CLEAR_DELETE_FILES";

const initialState = {
  isFirst: true,
  sbmsDocFiles: [],
  deleteFiles: [],
};

const sbmsDoc = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case INIT_SBMS_DOC_FILES:
      let _initSbmsDocFiles = state.sbmsDocFiles.map((item) => {
        let _item = Object.assign(item, {});
        _item = {..._item, file:[], fileInfo: []};
        return _item;
      });
      return {
        ...state,
        isFirst: true,
        sbmsDocFiles: _initSbmsDocFiles,
      };
    case SET_SBMS_DOC_FILE:
      return {
        ...state,
        sbmsDocFiles: rest.payload.initData,
      };
    case SET_UPDATE_FAILE:
      let _updateSbmsDocFiles = state.sbmsDocFiles.map((file) => {
        return { ...file, update: false };
      });
      return {
        ...state,
        sbmsDocFiles: _updateSbmsDocFiles,
      };
    case DELETE_HAS_BLANK_FILE:
      let _afterState = state.sbmsDocFiles.map((sbmsDocfileData) => {
        if (sbmsDocfileData.update === true) {
          let filterfileInfo = [];
          for (
            let i = 0;
            i < sbmsDocfileData.fileInfo.length - sbmsDocfileData.file.length;
            i++
          ) {
            filterfileInfo.push(sbmsDocfileData.fileInfo[i]);
          }
          return {
            ...sbmsDocfileData,
            file: [],
            fileInfo: filterfileInfo,
            update: false,
          };
        } else {
          return sbmsDocfileData;
        }
      });
      return {
        ...state,
        sbmsDocFiles: _afterState,
      };
    case SET_MODIFY_SBMS_DOC_FILE:
      const _sbmsDocFiles = [];

      state.sbmsDocFiles.forEach((sbmsDocFile) => {
        if (rest.payload.sbmsDocData.length) {
          let existDocFile = rest.payload.sbmsDocData.filter(
            (item) => Number(item.SBMS_DOC_NO) === Number(sbmsDocFile.docNo)
          );
          if (existDocFile.length) {
            _sbmsDocFiles.push({
              ...sbmsDocFile,
              file: sbmsDocFile.file.concat(existDocFile[0].FILE),
              fileInfo: sbmsDocFile.fileInfo.concat(existDocFile[0].FILE_INFO),
              update: rest.payload.isFirst ? false : true,
            });
          } else {
            _sbmsDocFiles.push(sbmsDocFile);
          }
        } else {
          _sbmsDocFiles.push(sbmsDocFile);
        }
      });
      return {
        ...state,
        isFirst: rest.payload.isFirst ? false : state.isFirst,
        sbmsDocFiles: _sbmsDocFiles,
      };
    case SET_SBMS_DOC_FILE_INFO:
      let _sbmsFileInfo = state.sbmsDocFiles.map((sbmsDocFile) => {
        let _tempData = Object.assign(sbmsDocFile, {});
        rest.payload.data.forEach((item) => {
          if(Number(item.SBMS_DOC_NO) === Number(sbmsDocFile.docNo)) {
            let _fileInfo = _tempData.fileInfo.concat({seqNo: item.SEQ_NO, fileNm: item.UPLD_FILE_NM, realFileNm: item.REAL_FILE_NM, midPath : item.MID_PATH})
            _tempData = {..._tempData, fileInfo: _fileInfo}
          }
        });
        return _tempData;
      });
      return {
        ...state,
        sbmsDocFiles: _sbmsFileInfo,
      };
    case DELETE_SBMS_DOC_FILE:
      let tempSbmsDocFiles = [];
      let fileArr = [];
      let fileInfoArr = [];

      tempSbmsDocFiles = state.sbmsDocFiles.map((tempData) => {
        if (Number(tempData.docNo) === Number(rest.payload.docNo)) {
          fileArr = tempData.file.filter(
            (item) => {
              return String(item.name) !== String(rest.payload.fileInfo.fileNm)
            }
          );
          fileInfoArr = tempData.fileInfo.filter(
            (item, index) => Number(index) !== Number(rest.payload.index)
          );

          return { ...tempData, file: fileArr, fileInfo: fileInfoArr };
        } else {
          return tempData;
        }
      });
      return {
        ...state,
        sbmsDocFiles: tempSbmsDocFiles,
      };
    case DELETE_PREV_SBMS_DOC_FILE:
      let _prevSbmsDocFiles = [];
      let _prevFileInfoArr = [];

      _prevSbmsDocFiles = state.sbmsDocFiles.map((tempData) => {
        if (Number(tempData.docNo) === Number(rest.payload.docNo)) {
          _prevFileInfoArr = tempData.fileInfo.filter(
            (item, index) => Number(index) !== Number(rest.payload.index)
          );

          return { ...tempData, fileInfo: _prevFileInfoArr };
        } else {
          return tempData;
        }
      });
      return {
        ...state,
        deleteFiles: state.deleteFiles.concat(rest.payload.seqNo),
        sbmsDocFiles: _prevSbmsDocFiles,
      };
    case CHANGE_SBMS_DOC_FILE:
      const { docNo } = rest.payload
      let newSbmsDocFiles = state.sbmsDocFiles.map((item) => {
        if(String(item.docNo) === String(docNo)) {
          return {
            ...item, 
            file: [],
            fileInfo: item.fileInfo.filter((item) => item.seqNo !== ''),
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        sbmsDocFiles: newSbmsDocFiles,
      };
    case SET_IS_FIRST_TRUE:
      return {
        ...state,
        isFirst: true,
      };
    case CLEAR_DELETE_FILES:
      return {
        ...state,
        deleteFiles: [],
      };
    case "persist/PURGE":
      return initialState;
    default:
      return state;
  }
};

export const initSbmsDocFile = () => ({
  type: INIT_SBMS_DOC_FILES,
});
export const setSbmsDocFile = (initData) => ({
  type: SET_SBMS_DOC_FILE,
  payload: { initData },
});
export const setModifySbmsDocFile = (sbmsDocData, isFirst) => ({
  type: SET_MODIFY_SBMS_DOC_FILE,
  payload: { sbmsDocData, isFirst },
});
export const setSbmsDocFileInfo = (data) => ({
  type: SET_SBMS_DOC_FILE_INFO,
  payload: { data },
});
export const deleteSbmsDocFile = ({ index, docNo, fileInfo }) => ({
  type: DELETE_SBMS_DOC_FILE,
  payload: { index, docNo, fileInfo },
});
export const deletePrevSbmsDocFile = (index, seqNo, docNo) => ({
  type: DELETE_PREV_SBMS_DOC_FILE,
  payload: { index, seqNo, docNo },
});
export const changeSbmsDocFile = (docNo) => ({
  type: CHANGE_SBMS_DOC_FILE,
  payload: { docNo },
});
export const setUpdateFalse = () => ({
  type: SET_UPDATE_FAILE,
});
export const deleteHasBlankFile = () => ({
  type: DELETE_HAS_BLANK_FILE,
});
export const setIsFirstTrue = () => ({
  type: SET_IS_FIRST_TRUE,
});
export const clearDeleteFiles = () => ({
  type: CLEAR_DELETE_FILES,
});

export default sbmsDoc;
