const SET_MODIFY_MEM_REG_FILE = "SET_MODIFY_MEM_REG_FILE";
const CLEAR_DELETE_FILES = "CLEAR_DELETE_FILES";

const initialState = {
  isFirst: true,
  memRegFiles: [],
  deleteFiles: [],
};

const memReg = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case SET_MODIFY_MEM_REG_FILE:
      const _memRegFiles = [];
      let existDocFile = rest.payload.memRegData;
        if (rest.payload.memRegData.length) {
          if (existDocFile.length) {
            _memRegFiles.push({
              file: existDocFile[0].FILE,
              fileInfo: existDocFile[0].FILE_INFO,
              update: rest.payload.isFirst ? false : true,
            });
          } else {
            _memRegFiles.push(existDocFile);
          }
        } else {
          _memRegFiles.push(existDocFile);
        }
      return {
        ...state,
        isFirst: rest.payload.isFirst ? false : state.isFirst,
        memRegFiles: _memRegFiles,
      };
  
    case CLEAR_DELETE_FILES:
      return {
        ...state,
        deleteFiles: [],
      };
    case "persist/PURGE":
      return initialState;
    default:
      return state;
  }
};


export const setModifyMemRegFile = (memRegData, isFirst) => ({
  type: SET_MODIFY_MEM_REG_FILE,
  payload: { memRegData, isFirst },
});

export const clearDeleteFiles = () => ({
  type: CLEAR_DELETE_FILES,
});

export default memReg;
