const SET_APO_DOC_CNFM_YN = "SET_APO_DOC_CNFM_YN";
const SET_MODIFY_APO_DOC_CNFM_YN = "SET_MODIFY_APO_DOC_CNFM_YN";
const SET_MODIFY_QLFC_DOC_CNFM_YN = "SET_MODIFY_QLFC_DOC_CNFM_YN";
const SET_APO_DOC_HDWT_DATA = "SET_APO_DOC_HDWT_DATA";
const SET_MODIFY_APO_DOC_HDWT_DATA = "SET_MODIFY_APO_DOC_HDWT_DATA";
const SET_CNFM_YN_ALL_CHECKED = "SET_CNFM_YN_ALL_CHECKED";

const initialState = {
  isFirst: true,
  apoDocCnfmYn: [],
  apoDocHdwtInfo: [
    {
      hdwtCatCd: "D01",
      text: "",
      data: undefined,
      update: false,
    },
    {
      hdwtCatCd: "D02",
      text: "종이문서, 휴대전화",
      data: undefined,
      update: false,
    },
    {
      hdwtCatCd: "D03",
      text: "모든 내용을 숙지하고 동의",
      data: undefined,
      update: false,
    },
  ],
};

const apoDocForm = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case SET_APO_DOC_CNFM_YN:
      return {
        ...state,
        apoDocCnfmYn: rest.payload.cnfmInfo,
      };
    case SET_MODIFY_APO_DOC_CNFM_YN:
      const _apoDocCnfmYn = state.apoDocCnfmYn.map((item) => {
        if (item.afcrNo === rest.payload.afcrNo) {
          return rest.payload.isChecked
            ? {
                ...item,
                cnfmYn: false,
                update: true,
              }
            : {
                ...item,
                cnfmYn: true,
                update: true,
              };
        } else {
          return item;
        }
      });
      return {
        ...state,
        apoDocCnfmYn: _apoDocCnfmYn,
      };
      case SET_MODIFY_QLFC_DOC_CNFM_YN:
        const _qlfcDocCnfmYn = state.apoDocCnfmYn.map((item) => {
          if (item.formCatDtlCd === rest.payload.formCatDtlCd) {
            return rest.payload.isChecked
              ? {
                  ...item,
                  cnfmYn: false,
                  update: true,
                }
              : {
                  ...item,
                  cnfmYn: true,
                  update: true,
                };
          } else {
            return item;
          }
        });
        return {
          ...state,
          apoDocCnfmYn: _qlfcDocCnfmYn,
        };
    case SET_APO_DOC_HDWT_DATA:
      const _apoDocHdwtInfo = [];
      state.apoDocHdwtInfo.forEach((data) => {
        if (rest.payload.changeData.length) {
          let existHdwtData = rest.payload.changeData.filter(
            (item) => item.HDWT_CAT_CD === data.hdwtCatCd
          );
          if (existHdwtData.length) {
            _apoDocHdwtInfo.push({
              ...data,
              data: existHdwtData[0].IMG_DATA,
              update: rest.payload.isFirst ? false : true,
            });
          } else {
            _apoDocHdwtInfo.push(data);
          }
        } else {
          _apoDocHdwtInfo.push(data);
        }
      });
      return {
        ...state,
        isFirst: rest.payload.isFirst ? false : state.isFirst,
        apoDocHdwtInfo: _apoDocHdwtInfo,
      };
    case SET_MODIFY_APO_DOC_HDWT_DATA:
      const __apoDocHdwtInfo = state.apoDocHdwtInfo.map((item, index) => {
        return index === rest.payload.index
          ? { ...item, data: undefined, update: false }
          : item;
      });
      return {
        ...state,
        apoDocHdwtInfo: __apoDocHdwtInfo,
      };
    case SET_CNFM_YN_ALL_CHECKED:
      const isAllChecked = state.apoDocCnfmYn.filter((item) => item.cnfmYn);
      const newApoDocCnfmYn = state.apoDocCnfmYn.map((item) => {
        if(item.afcrNo === '') {
          return item;
        } else {
          return { ...item, cnfmYn: isAllChecked.length === state.apoDocCnfmYn.length ? false : true, update: true };
        }
      });
      return {
        ...state,
        apoDocCnfmYn: newApoDocCnfmYn,
      };
    case "persist/PURGE":
      return initialState;
    default:
      return state;
  }
};

export const setApoDocCnfmYn = (cnfmInfo) => ({
  type: SET_APO_DOC_CNFM_YN,
  payload: { cnfmInfo },
});
export const setModifyApoDocCnfmYn = (afcrNo, isChecked) => ({
  type: SET_MODIFY_APO_DOC_CNFM_YN,
  payload: { afcrNo, isChecked },
});
export const setModifyqlfcDocCnfmYn = (formCatDtlCd, isChecked) => ({
  type: SET_MODIFY_QLFC_DOC_CNFM_YN,
  payload: { formCatDtlCd, isChecked },
});
export const setApoDocHdwtData = (changeData, isFirst) => ({
  type: SET_APO_DOC_HDWT_DATA,
  payload: { changeData, isFirst },
});
export const setModifyApoDocHdwtData = (index) => ({
  type: SET_MODIFY_APO_DOC_HDWT_DATA,
  payload: { index },
});
export const handleCnfmYnAllChecked = () => ({
  type: SET_CNFM_YN_ALL_CHECKED,
});

export default apoDocForm;
